import React, { createContext, useContext, useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { IS_LOGGED_IN } from './../../utils/constants';

export interface LoggedInUser {
	IdJwtToken: CognitoIdToken;
	Email: string;
	Name: string;
	Permissions: any;
	Features: [any];
	Roles: [string];
	UserId: string;
	IsVerified: boolean;
}

export interface CognitoUserAttributes {
	email: string;
	given_name: string;
	family_name: string;
	'custom:postcode': string;
	'custom:primary_user_type': string;
}

// Cognito global configuration.
Auth.configure({
	mandatorySignIn: true,
	region: process.env.GATSBY_AUTH_REGION,
	userPoolId: process.env.GATSBY_AUTH_USER_POOL_ID,
	userPoolWebClientId: process.env.GATSBY_AUTH_USER_POOL_WEBCLIENT_ID
});
const authContext = createContext(null);
// Hook for child components to get the auth objects. This will re-render when it changes.
export const useAuth = () => {
	return useContext(authContext);
};

// Provider component that wraps your app and makes auth object. This is available to any child component that calls useAuth().
export function AuthProvider({ children }) {
	const auth = useCognitoAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook to make use of the federated auth
const useCognitoAuth = () => {
	const [currentUser, setCurrentUser] = useState(null);

	const getCurrentUser = async function () {
		return Auth.currentSession()
			.then((session) => {
				if (session) {
					localStorage.setItem(IS_LOGGED_IN, 'true');
					const idToken = session.getIdToken();
					const loggedInUser = convertToLoggedInUser(idToken);
					return loggedInUser;
				} else {
					localStorage.removeItem(IS_LOGGED_IN);
					return null;
				}
			})
			.catch(() => {
				return null;
			});
	};

	const processCurrentUser = () => {
		// IF the user is present
		Auth.currentSession()
			.then((session) => {
				if (session) {
					const idToken = session.getIdToken();
					const loggedInUser = convertToLoggedInUser(idToken);
					setCurrentUser(loggedInUser);
				}
			})
			.catch(() => {
				return null;
			});
	};
	useEffect(() => {
		Hub.listen('auth', ({ payload: { event } }) => {
			switch (event) {
				case 'signIn':
					processCurrentUser();
					break;

				case 'signOut':
					setCurrentUser(null);
					localStorage.removeItem("notificationListIds");
					break;

				case 'signIn_failure':
					console.log('the user failed to sign in');
			}
		});

		// If the user is already logged in, use the information from the state.
		processCurrentUser();
	}, []);

	const convertToLoggedInUser = function (
		idToken: CognitoIdToken
	): LoggedInUser {
		return {
			IdJwtToken: idToken,
			Email: idToken.payload.email,
			Name: `${idToken.payload.given_name} ${idToken.payload.family_name}`,
			Permissions: null, //idToken.payload.permissions.split(" "),
			Features: JSON.parse(idToken.payload.features),
			Roles: idToken.payload.roles.split(' '),
			UserId: idToken.payload.sub,
			IsVerified: idToken.payload.email_verified
		};
	};

	const signIn = async (userName, password) => {
		await signOut();
		const user = await Auth.signIn(userName, password);
		if (user) {
			localStorage.setItem(IS_LOGGED_IN, 'true');
		}
		return user;
	};

	const signOut = async () => {
		await Auth.signOut();
		localStorage.removeItem(IS_LOGGED_IN);
	};

	const signUp = async function (
		email: string,
		password: string,
		attributes: CognitoUserAttributes,
		metadata: any
	) {
		return await Auth.signUp({
			username: email,
			password: password,
			attributes: attributes,
			clientMetadata: metadata
		});
		//TODO: can automatically login here if required.
	};

	const resendVerificationLink = async (username) => {
		return await Auth.resendSignUp(username);
	};

	const changeUserPassword = async function (
		oldPassword: string,
		newPassword: string
	) {
		//return await Auth.verifyCurrentUserAttribute("email");
		const currentUser = await Auth.currentAuthenticatedUser();
		return await Auth.changePassword(currentUser, oldPassword, newPassword);
	};

	const receiveVerificationCode = async function (email: string) {
		try {
			await Auth.signIn(email, 'fake1234');
		} catch (error) {
			if (error.message.includes('UserNotConfirmed')) {
				await Auth.resendSignUp(email);
				return 'signup sent again';
			} else {
				return await Auth.forgotPassword(email);
			}
		}
	};

	const changePasswordWithVerificationCode = async (
		email: string,
		code: string,
		newPassword: string
	) => {
		await Auth.forgotPasswordSubmit(email, code, newPassword);
	};

	const updateCurrentUserAttributes = async (
		attributes: CognitoUserAttributes
	) => {
		const user = await Auth.currentAuthenticatedUser();
		if (user) {
			return await Auth.updateUserAttributes(user, attributes);
		}
		return Promise.reject();
	};

	const confirmSignupEmailCode = async (username: string, code: string) => {
		await Auth.confirmSignUp(username, code);
	};

	const getUserAttributes = async () => {
		const user = await Auth.currentAuthenticatedUser();
		if (user) {
			return await Auth.userAttributes(user);
		}
		return Promise.reject();
	};

	const verifyCurrentUserAttribute = async (
		attributes: string,
		code: string
	) => {
		const user = await Auth.currentAuthenticatedUser();
		if(user) {
			return await Auth.verifyCurrentUserAttributeSubmit(attributes, code);
		}	
		return Promise.reject();
	};

	const verifyUserAttribute = async (
		attributes: string
	) => {
		const user = await Auth.currentAuthenticatedUser();
		if(user) {
			return await Auth.verifyUserAttribute(user, attributes);
		}
		return Promise.reject();
	};



	return {
		signUp,
		signIn,
		signOut,
		currentUser,
		updateCurrentUserAttributes,
		changeUserPassword,
		receiveVerificationCode,
		resendVerificationLink,
		changePasswordWithVerificationCode,
		confirmSignupEmailCode,
		getCurrentUser,
		verifyCurrentUserAttribute,
		verifyUserAttribute,
		getUserAttributes
	};
};

export default useCognitoAuth;
