export const DASHBOARD_ROUTE = '/';
export const MY_STABLE_ROUTE = '/?activeTab=my-stable';
export const EVENT_CALENDAR_ROUTE = '/events';
export const OTT_PARTNERSHIPS_ROUTE = '/ott-partnerships';
export const CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE = '/claim-thoroughbred-review';
export const PREVIOUS_CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE =
	'/previous-claim-thoroughbred-review';
export const LOGIN_ROUTE = '/login';
export const PASSWORD_RESET_ROUTE = '/password-reset';
export const PROGRAMS_CLAIM_THOROUGHBRED_ROUTE = '/programs/claim-thoroughbred';
export const OHEP_FORM__ID_ROUTE = '/ohep-form/:id';
export const OHEP_FORM_REVIEW__ID_ROUTE = '/ohep-form-review/:id';
export const PROGRAMS_OHEP_ROUTE = '/programs/ohep';
export const PROGRAMS_OHEP_LANDING_PAGE_ROUTE = '/programs/ohep';
export const OHEP_PRIVACY_ROUTE = '/ohep-privacy';
export const OHEP_EOI_FORM__ID_ROUTE = '/ohep-eoi-form/:id';
export const OHEP_EOI_FORM__ROUTE = '/ohep-eoi-form';
export const OHEP_EOI_FORM_REVIEW__ID_ROUTE = '/ohep-eoi-form-review/:id';
export const OHEP_NTP_FORM__ID_ROUTE = '/notice-to-proceed/:id';
export const ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE =
	'/acknowledged-retrainer-privacy-collection-statement';
export const OHEP_PROVIDERS_ROUTE = '/ohep-providers';
export const ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE =
	'/acknowledged-retirement-farm-privacy-collection-statement';
export const SAFETY_NET_PROGRAM_REPORTS_ROUTE =
	'/safety-net-program-reports/:id';
export const DECEASED_THOROUGHBRED_FORM_ROUTE =
	'/deceased-thoroughbred-form/:id';
export const PAGE_NOT_FOUND_ROUTE = '/page-not-found';
export const FIND_YOUR_THOROUGHBRED_FORM = '/thoroughbred-registration-form';
export const THOROUGHBRED_UNREGISTERED_FORM = '/thoroughbred-unregistered-form';
export const THOROUGHBRED_UNREGISTERED_FORM__ID_ROUTE =
	'/thoroughbred-unregistered-form/:id';
export const THOROUGHBRED_UNREGISTERED_FORM_REVIEW__ID_ROUTE =
	'/thoroughbred-unregistered-form-review/:id';
export const EVENT_REPORTING_ROUTE = '/event-reporting';
export const EVENT_REPORTING_REQUEST_ROUTE = '/event-request-outcome-report';
export const SPONSORSHIP_OUTCOME_ID_ROUTE = '/sponsorship-outcome-form/:id';
export const SPONSORSHIP_OUTCOME_ROUTE = '/sponsorship-outcome-form';
export const SPONSORSHIP_OUTCOME_REVIEW_ROUTE = '/sponsorship-outcome-review';
